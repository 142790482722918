import { RootStore } from './root';
import { v4 as uuidv4 } from 'uuid';
import { createBrowserHistory, Location, History } from 'history';
import { LOGIN_MAIN_PAGE_BASIC_URL, SEARCH_PARAMS, USERS_BASE_URL, WIXEL_HOME_URL } from '../utils/constants';
import { isValidUrl } from '../utils/validators';
import { action, makeObservable, when } from 'mobx';
import { DEFAULT_ROUTE, ROUTES } from '../routes';
import { onSwitchContext } from '@wix/bi-logger-hls2/v2';
import { concatParameterToUrl, isAnswersUrl } from '../utils/general';
import { blockedAccountPagePageViewSrc5Evid259 } from '@wix/bi-logger-identity-data/v2';
import { AuthType } from '../components/BlockedAccount/authTypes';
import { BackButtonVisibility } from '../types';
import { PRESETS } from '../utils/presets';

const MAP_ROUTE_TO_CONTEXT = {
  [ROUTES.LOGIN_PASSWORD]: 'login',
  [ROUTES.SIGNUP_PASSWORD]: 'signup',
};

const LOGIN_TO_POST_LOGIN_INTERACTION_NAME = 'login-to-post-login';

export class NavigationStore {
  private readonly rootStore: RootStore;
  private historyChangesListeners: Map<string, (location: Location) => any>;
  private readonly POST_SIGNUP_DEFAULT_URL = 'https://www.wix.com/new/intro';
  private readonly publicOrigin: string;
  isLoaded: boolean = false;
  baseUrl: string;
  baseDomain: string;
  targetUrl: string;
  postLoginUrl: string;
  postSignupUrl: string;
  history: History;
  loginPageUrl: string;
  originUrl?: string;
  overrideLocale?: string;
  loginDialogContext?: string;
  originContext?: string;
  defaultEmail?: string;
  backButtonVisibility?: BackButtonVisibility = 'default';

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.historyChangesListeners = new Map();
    this.baseUrl = window.__USER_TYPE__
      ? `/${window.__USER_TYPE__}/signin`
      : window.__BASEURL__;
    this.baseDomain = window.__BASE_DOMAIN__;
    this.history = createBrowserHistory({ basename: this.baseUrl });
    this.initUrls();
    this.publicOrigin = `https://${
      this.rootStore.language.locale === 'en'
        ? 'www'
        : this.rootStore.language.locale
    }.${this.baseDomain}`;
    this.setHistoryListener();
    this.isLoaded = true;
    this.getQueryParam = this.getQueryParam.bind(this);
    this.curryNavigate = this.curryNavigate.bind(this);
    this.navigate = this.navigate.bind(this);
    this.overrideLocale = this.getQueryParam('overrideLocale');
    this.loginDialogContext = this.getQueryParam(
      SEARCH_PARAMS.LOGIN_DIALOG_CONTEXT,
    );
    this.originContext = this.getQueryParam(SEARCH_PARAMS.ORIGIN_CONTEXT);
    this.defaultEmail = this.getQueryParam(SEARCH_PARAMS.DEFAULT_EMAIL);
    const backButtonVisibility = this.getQueryParam(
      SEARCH_PARAMS.BACK_BUTTON_VISIBILITY,
    );
    if (backButtonVisibility) {
      this.backButtonVisibility = backButtonVisibility as BackButtonVisibility;
    }
    makeObservable(this, { getPublicAbsoluteUrl: action.bound });
  }

  get locationParams() {
    return new URLSearchParams(location.search);
  }

  get locationParamsEntries() {
    return Object.fromEntries(this.locationParams.entries());
  }

  initUrls() {
    const getUrlParam = (paramName: string) => {
      const urlParam = this.getQueryParam(paramName);
      return isValidUrl(urlParam) ? urlParam : '';
    };
    const loginPageUrl = getUrlParam('loginPageUrl');
    this.loginPageUrl =
      loginPageUrl && loginPageUrl !== ''
        ? loginPageUrl
        : this.trimAfterAuth(location.href);
    this.postLoginUrl = getUrlParam(SEARCH_PARAMS.POST_LOGIN_URL);
    this.postSignupUrl = getUrlParam(SEARCH_PARAMS.POST_SIGNUP_URL);
    this.targetUrl = getUrlParam(SEARCH_PARAMS.TARGET_URL);
    this.originUrl = getUrlParam(SEARCH_PARAMS.ORIGIN_URL);
  }

  trimAfterAuth(url) {
    return url.replace(/(\/(signin|login))\/.*$/, '$1'); // Keep signin/login and remove everything after
  }

  // NOTE! Should only be used when specs.ident.NewLoginReactApp=false
  goToMainLogin({
    email,
    parentHint,
  }: {
    email?: string;
    parentHint?: string;
  }) {
    let url = this.loginPageUrl;
    if (email) {
      url = concatParameterToUrl(url, 'defaultEmail', email);
    }
    if (this.targetUrl) {
      url = concatParameterToUrl(
        url,
        SEARCH_PARAMS.POST_LOGIN_URL,
        this.targetUrl,
      );
    }
    if (parentHint) {
      url = concatParameterToUrl(url, 'parentHint', parentHint);
    }
    this.redirect(url);
  }

  async postLogin({
    uaToken,
    authMethod,
  }: {
    uaToken?: string;
    authMethod?: string;
  }) {
    this.rootStore.fedopsLogger.interactionStarted(LOGIN_TO_POST_LOGIN_INTERACTION_NAME);
    const customParams = {}
    const shouldRedirectToPostLogin =
      this.rootStore.experiments.enabled('specs.ident.postLogin') &&
      this.getQueryParam(SEARCH_PARAMS.REFERRAL_INFO) === 'SignUp_H';
    customParams['shouldRedirectToPostLogin'] = shouldRedirectToPostLogin;

    const postLogin = this.getQueryParam(SEARCH_PARAMS.POST_LOGIN_URL);
    customParams['postLogin'] = postLogin;
    const redirectTo = this.getQueryParam(SEARCH_PARAMS.REDIRECT_TO_URL);
    customParams['redirectTo'] = redirectTo;
    const showWorkspaceSelector = this.getQueryParam(
      SEARCH_PARAMS.SHOW_WORKSPACE_SELECTOR,
    );
    customParams['showWorkspaceSelector'] = showWorkspaceSelector;
    const inviteToken = this.getQueryParam(SEARCH_PARAMS.INVITE_TOKEN);
    customParams['inviteToken'] = inviteToken;
    let redirectionUrl;
    try {
      const { url } = await this.rootStore.apiStore.getFunnelRedirectUrl({
        precondition: 'SIGN_IN',
        postAuthUrl: encodeURIComponent(postLogin ?? ''),
        redirectToUrl: encodeURIComponent(redirectTo ?? ''),
        authMethod,
      });
      redirectionUrl = new URL(url, this.publicOrigin);
      customParams['redirectionUrl'] = redirectionUrl;
    } catch (error: any) {
      customParams['getFunnelRedirectUrlError'] = error;

      const defaultRedirect = '/my-account';
      customParams['defaultRedirect'] = defaultRedirect;
      redirectionUrl = new URL(
        postLogin || redirectTo || defaultRedirect,
        this.publicOrigin,
      );
      customParams['redirectionUrl'] = redirectionUrl;
    }
    this.handleAnswersUrl(redirectionUrl, uaToken);
    customParams['redirectionUrl'] = redirectionUrl;
    if (inviteToken && showWorkspaceSelector) {
      customParams['inviteToken'] = inviteToken;
      customParams['showWorkspaceSelector'] = showWorkspaceSelector;
      // get user workspaces and if has multiple, redirect to workspace selector
      const workspaces =
        await this.rootStore.postLoginStore.fetchUserWorkspaces();
      customParams['workspaces'] = workspaces;
      if (workspaces && workspaces.length > 1) {
        const workspaceSelectorUrl = this.buildWorkspaceSelectorUrl(redirectionUrl);
        customParams['workspaceSelectorUrl'] = workspaceSelectorUrl;
        customParams['reason'] = 'redirecting to workspace selector url';
        this.rootStore.fedopsLogger.interactionEnded(LOGIN_TO_POST_LOGIN_INTERACTION_NAME, {
            customParams: customParams
        });
        return (location.href = workspaceSelectorUrl);
      }
    }
    if (shouldRedirectToPostLogin) {
      customParams['reason'] = 'navigating to post login';
      this.rootStore.fedopsLogger.interactionEnded(LOGIN_TO_POST_LOGIN_INTERACTION_NAME, {
          customParams: customParams
      });
      return this.navigateToPostLogin(redirectionUrl.toString());
    }
    customParams['reason'] = 'proceeding to post auth url';
    this.rootStore.fedopsLogger.interactionEnded(LOGIN_TO_POST_LOGIN_INTERACTION_NAME, {
        customParams: customParams
    });
    this.proceedToPostAuthUrl(redirectionUrl.toString());
  }

  public navigateToPostLogin(targetUrl: string) {
    this.rootStore.postLoginStore.targetUrl = targetUrl;
    this.navigate(ROUTES.POST_LOGIN);
  }

  public navigateToAccountSelector() {
    when(
      () => this.rootStore.selectAccountStore.enableAccess(),
      () => this.rootStore.navigationStore.navigate(ROUTES.SELECT_ACCOUNT),
    );
  }

  public navigateToLogin() {
    const userType = this.getQueryParam('type');
    if (userType) {
      return this.redirect(`${USERS_BASE_URL}/${userType}/signin`);
    }
    this.redirect(LOGIN_MAIN_PAGE_BASIC_URL);
  }

  postSignup({
    uaToken,
    ssoUrl, // This is internal ssoUrl (wix <-> editorx)
    authMethod,
  }: {
    uaToken?: string;
    ssoUrl?: string;
    authMethod?: string;
  }) {
    this.rootStore.tagManagerStore.log('Successful Signup');
    const redirectTo = this.getQueryParam(SEARCH_PARAMS.REDIRECT_TO_URL);
    const explicitRedirectUrl = this.postSignupUrl || redirectTo;
    setTimeout(() => {
      this.rootStore.apiStore
        .getFunnelRedirectUrl({
          precondition: 'SIGN_UP',
          postAuthUrl: encodeURIComponent(this.postSignupUrl),
          redirectToUrl: encodeURIComponent(redirectTo ?? ''),
          authMethod,
        })
        .then(({ url }) => {
          let redirectionUrl = new URL(url, this.publicOrigin);
          this.handleAnswersUrl(redirectionUrl, uaToken);
          redirectionUrl = this.handleInternalSso(redirectionUrl, ssoUrl);
          this.proceedToPostAuthUrl(redirectionUrl.toString());
        })
        .catch((error) => {
          console.error(error);
          const redirectionUrl = new URL(
            explicitRedirectUrl ?? this.POST_SIGNUP_DEFAULT_URL,
            this.publicOrigin,
          );
          this.proceedToPostAuthUrl(redirectionUrl.toString());
        });
    }, 1000);
  }

  appendSearchParamToCurrentLocation(paramName: string, paramValue: string) {
    const url = new URL(window.location.href);
    url.searchParams.set(paramName, paramValue);
    const path = url.toString();
    window.history.replaceState({ path }, '', path);
  }

  redirect(
    url: string,
    {
      keepRelative,
      searchParams,
    }: {
      keepRelative?: boolean;
      searchParams?: { [key: string]: string };
    } = {},
  ) {
    let redirectUrl = keepRelative ? url : this.getPublicAbsoluteUrl(url);
    if (searchParams) {
      const _url = new URL(redirectUrl);
      Object.keys(searchParams).forEach((key) =>
        _url.searchParams.set(key, searchParams[key]),
      );
      redirectUrl = _url.toString();
    }
    if (keepRelative || isValidUrl(redirectUrl)) {
      window.location.href = redirectUrl;
    }
  }

  getPublicAbsoluteUrl(url: string) {
    try {
      if (url.startsWith('/')) {
        return new URL(url, this.publicOrigin).toString();
      } else if (!/^https:\/\//i.test(url)) {
        // if the url doesn't start with https://, replace the protocol with https://
        return url.replace(/^http:\/\//i, 'https://');
      }
    } catch (error) {
      console.error(error);
    }
    return url;
  }

  navigate(path: string, context?: string) {
    // user redirect when 'path' is an absolute url
    if (isValidUrl(path)) {
      return this.redirect(path);
    }
    this.rootStore.biLogger.report(
      onSwitchContext({
        context: context ?? MAP_ROUTE_TO_CONTEXT[path] ?? path,
        referrer: this.originContext,
      }),
    );
    this.history.push(path + this.history.location.search);
  }

  navigateToBlockedAccount({
    context,
    refferal_info,
  }: {
    context?: string;
    refferal_info: AuthType;
  }) {
    this.navigate(ROUTES.BLOCKED_ACCOUNT, context);
    this.rootStore.biLogger.report(
      blockedAccountPagePageViewSrc5Evid259({
        refferal_info,
      }),
    );
  }

  curryNavigate(path: string) {
    return () => this.navigate(path);
  }

  goBack = () => {
    this.history.goBack();
  };

  navigateToTargetUrl() {
    this.targetUrl && this.redirect(this.targetUrl);
  }

  // TODO - change DEFAULT_ROUTE to EMAIL_STEP when 'specs.ident.NewLoginReactApp' is merged
  navigateToDefaultRoute() {
    return this.rootStore.isNewLoginApp ? ROUTES.EMAIL_STEP : DEFAULT_ROUTE;
  }

  refreshPage() {
    window.location.reload();
  }

  registerHistoryChanges(callback: (location: Location) => any) {
    const callbackId = uuidv4();
    this.historyChangesListeners.set(callbackId, callback);
    return callbackId;
  }

  unRegisterHistoryChanges(callbackId: string) {
    this.historyChangesListeners.delete(callbackId);
  }

  get currentRoute(): string {
    return this.history.location.pathname;
  }

  get isSsoRoute(): boolean {
    return this.currentRoute.indexOf('/sso') > -1;
  }

  getQueryParam(paramName: string): string {
    return this.locationParams.get(paramName) ?? '';
  }

  proceedToPostAuthUrl(url: string) {
    if (!this.rootStore.displayStore.isFullScreenMode) {
      return this.rootStore.modalModeHandlerStore.onAuthCompleted(url);
    }
    this.rootStore.addAttributeToWixRecorder('postAuthRedirectionUrl', url);
    this.redirect(url);
  }

  private buildWorkspaceSelectorUrl(redirectUrl: string) {
    return `/login/select-workspace?redirect_to=${redirectUrl}`;
  }

  private handleAnswersUrl(url: URL, uaToken?: string) {
    if (isAnswersUrl(url.toString()) && uaToken) {
      url.searchParams.append('uaToken', uaToken);
    }
  }

  private handleInternalSso(url: URL, ssoUrl?: string) {
    let redirectionUrl = url;
    if (ssoUrl) {
      const finalLocation = url.toString();
      redirectionUrl = new URL(ssoUrl);
      redirectionUrl.searchParams.append(
        SEARCH_PARAMS.TARGET_URL,
        finalLocation,
      );
    }
    return redirectionUrl;
  }

  private setHistoryListener() {
    this.history.listen((location) => {
      this.historyChangesListeners.forEach((cb) => {
        cb(location);
      });
    });
  }

  async navigateToEnforcement() {
    await this.rootStore.postLoginStore.premiumUsers2FaEnforcementStore.init();
    const { PREMIUM_USERS_2FA_ENFORCEMENT, WIX_APP_ENFORCEMENT } =
      this.rootStore.postLoginStore.postLoginRoutes;
    const newEnforcementExperience =
      this.rootStore.postLoginStore.shouldShowWixAppEnforcement;
    this.navigate(
      newEnforcementExperience
        ? WIX_APP_ENFORCEMENT
        : PREMIUM_USERS_2FA_ENFORCEMENT,
    );
  }

  get targetUrlForSso() {
    if (this.postLoginUrl) {
      return this.postLoginUrl;
    }
    if (this.targetUrl) {
      return this.targetUrl;
    }
    if (this.rootStore.userType === PRESETS.WIXEL) {
      return WIXEL_HOME_URL;
    }
    return undefined;
  }
}
